import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-birtan'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, trainees) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: vm.getOfficeName(data.office_id), style: 'address', alignment: 'center' }
        ]
      pdfContent.push({ text: (i18n.locale === 'en' ? 'Under the revenue budget under the management of' : '') + ' ' + vm.getOfficeName(data.office_id) + ' ' + (i18n.locale === 'bn' ? 'এর ব্যবস্থাপনায় রাজস্ব বাজেটের আওতায়' : '') + ' ' + (vm.date) + ' ' + (i18n.locale === 'bn' ? vm.getDistrict(vm.training.district_id) + ' ' + 'জেলার' + ' ' + vm.getUpazila(vm.training.upazila_id) + ' ' + 'উপজেলায়' : ' at upazila' + ' ' + vm.getUpazila(vm.training.upazila_id) + ' ' + 'in district' + vm.getDistrict(vm.training.district_id)) + ' ' + (i18n.locale === 'en' ? 'Allowance payment sheet for trainees attending training course conducted in ' : '') + '' + (i18n.locale === 'bn' ? data.training_title_bn : data.training_title) + ' ' + (i18n.locale === 'bn' ? 'বিষয়ে বাস্তবায়িত প্রশিক্ষণ কোর্সে অংশগ্রহণকারী প্রশিক্ষণার্থীদের ভাতা প্রদানের শীট।' : '') + ' ' + (i18n.locale === 'bn' ? '' : ''), style: 'para', alignment: 'left' })
      if (trainees.length && vm.materials.length && vm.$route.query.otherTraineeType < 2 && vm.$route.query.payment_type === 'Trainee') {
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.name_of_trainee') + (i18n.locale === 'bn' ? ' ও ' : ' & ') + vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_config.organization') + '/' + vm.$t('elearning_tim.institution'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.materials'), style: 'th', colSpan: vm.materials.length, alignment: 'center', bold: true }
        ]
        if (vm.materials.length > 1) {
          for (let i = 1; i < vm.materials.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        firstHeader.push({ text: vm.$t('elearning_tim.per_person_daily_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$n(vm.days) + (i18n.locale === 'bn' ? ' দিনের ' : ' days ') + vm.$t('elearning_tim.total_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.remarks'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['7%', '10%', '10%', '10%']
        vm.materials.forEach(dateItem => {
          secondHeader.push(
            { text: i18n.locale === 'bn' ? dateItem.name_bn : dateItem.name, style: 'th', alignment: 'center', bold: true }
          )
          widthList.push('7%')
        })
        secondHeader.push({})
        widthList.push('*', '*', '*', '*')
        allRows.push(secondHeader)
        trainees.filter((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.personal.name_bn : info.personal.name, alignment: 'center', style: 'td' },
            { text: (info.personal.designation && i18n.locale === 'bn') ? info.personal.designation_bn : info.personal.designation, alignment: 'center', style: 'td' },
            { text: (info.personal.other_org && i18n.locale === 'bn') ? info.personal.other_org_bn : info.personal.other_org, alignment: 'center', style: 'td' }
          ]
          vm.materials.forEach((material, index1) => {
            rowItem.push(
              { text: vm.$n(material.quantity), alignment: 'center', style: 'td' }
            )
          })
          rowItem.push({ text: vm.$n(info.honour_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({ text: vm.$n(info.payment_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({})
          rowItem.push({})
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
      } else if (trainees.length && vm.materials.length && vm.$route.query.otherTraineeType > 1 && vm.$route.query.payment_type === 'Trainee') {
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.name_of_trainee') + (i18n.locale === 'bn' ? ' ও ' : '&') + vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.address'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.materials'), style: 'th', colSpan: vm.materials.length, alignment: 'center', bold: true }
        ]
        if (vm.materials.length > 1) {
          for (let i = 1; i < vm.materials.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        firstHeader.push({ text: vm.$t('elearning_tim.per_person_daily_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$n(vm.days) + ' ' + (i18n.locale === 'bn' ? ' দিনের ' : ' days ') + ' ' + vm.$t('elearning_tim.total_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.remarks'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['7%', '10%', '10%', '10%']
        vm.materials.forEach(dateItem => {
          secondHeader.push(
            { text: i18n.locale === 'bn' ? dateItem.name_bn : dateItem.name, style: 'th', alignment: 'center', bold: true }
          )
          widthList.push('7%')
        })
        secondHeader.push({})
        widthList.push('*', '*', '*', '*')
        allRows.push(secondHeader)
        trainees.filter((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.personal.name_bn : info.personal.name, alignment: 'center', style: 'td' },
            { text: (info.personal.designation && i18n.locale === 'bn') ? info.personal.designation_bn : info.personal.designation, alignment: 'center', style: 'td' },
            { text: info.personal.other_address, alignment: 'center', style: 'td' }
          ]
          vm.materials.forEach((material, index1) => {
            rowItem.push(
              { text: vm.$n(material.quantity), alignment: 'center', style: 'td' }
            )
          })
          rowItem.push({ text: vm.$n(info.honour_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({ text: vm.$n(info.payment_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({})
          rowItem.push({})
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
      } else if (trainees.length && vm.materials.length && vm.$route.query.payment_type === 'Trainer') {
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.name_of_trainee') + (i18n.locale === 'bn' ? ' ও ' : '&') + vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_config.organization'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.materials'), style: 'th', colSpan: vm.materials.length, alignment: 'center', bold: true }
        ]
        if (vm.materials.length > 1) {
          for (let i = 1; i < vm.materials.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        firstHeader.push({ text: vm.$t('elearning_tim.per_person_daily_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$n(vm.days) + ' ' + (i18n.locale === 'bn' ? ' দিনের ' : ' days ') + ' ' + vm.$t('elearning_tim.total_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.remarks'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['7%', '10%', '10%', '10%']
        vm.materials.forEach(dateItem => {
          secondHeader.push(
            { text: i18n.locale === 'bn' ? dateItem.name_bn : dateItem.name, style: 'th', alignment: 'center', bold: true }
          )
          widthList.push('7%')
        })
        secondHeader.push({})
        widthList.push('*', '*', '*', '*')
        allRows.push(secondHeader)
        trainees.filter((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.personal.name_bn : info.personal.name, alignment: 'center', style: 'td' },
            { text: (info.personal.designation && i18n.locale === 'bn') ? info.personal.designation_bn : info.personal.designation, alignment: 'center', style: 'td' },
            { text: (info.personal.other_org && i18n.locale === 'bn') ? info.personal.other_org_bn : info.personal.other_org, alignment: 'center', style: 'td' }
          ]
          vm.materials.forEach((material, index1) => {
            rowItem.push(
              { text: vm.$n(material.quantity), alignment: 'center', style: 'td' }
            )
          })
          rowItem.push({ text: vm.$n(info.honour_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({ text: vm.$n(info.payment_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({})
          rowItem.push({})
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
      } else {
        pdfContent.push({ text: vm.$t('elearning_tim.no_material_found'), style: 'hh', alignment: 'center' })
      }
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Protrait',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 13,
          bold: true,
          margin: [5, -25, 5, 5]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      para: {
          fontSize: 10,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
